import React, { useState, useEffect, useMemo } from 'react';
import Switch from 'react-switch';
import { useDispatch, useSelector } from 'react-redux';
import PropsTypes from 'prop-types';

import ModalWindow from '../../containers/ModalWindow';
import { CancelButton, PrimaryButton } from '../../components/UIKit';

import { getModalsChangeColumnOpenedSelector } from '../../redux-store/selectors/modals';
import { getPagesSettingsSelector } from '../../redux-store/selectors/pagesSettings';

import { CHANGE_COLUMN_MODAL_ID, closeModalWindow } from '../../containers/ModalWindow/slice';
import { setPagesSettingColumns } from '../../containers/PagesSettings/slice';
import { getOptionalColumnsSwitchers } from './constants';

import i18n from '../../i18n';

import './index.scss';
import { getSignInUserRoleTypeSelector } from '../../redux-store/selectors/signIn';

const ChangeColumnsModalWindow = (props) => {
  const { dataListID, page = '' } = props;
  const dispatch = useDispatch();

  const settings = useSelector(getPagesSettingsSelector);
  const isChangeColumnsModalOpened = useSelector(getModalsChangeColumnOpenedSelector);

  const columnsSettings = settings?.[dataListID]?.columns;

  const [data, setData] = useState(columnsSettings);

  const handleCancelBtnClick = () => dispatch(closeModalWindow({ modalID: CHANGE_COLUMN_MODAL_ID }));
  const handleSave = () => {
    dispatch(setPagesSettingColumns({ page: dataListID, data }));
    dispatch(closeModalWindow({ modalID: CHANGE_COLUMN_MODAL_ID }));
  };
  const handleChange = (key) => setData((prev) => ({ ...prev, [key]: !prev[key] }));
  const myRoleType = useSelector(getSignInUserRoleTypeSelector);
  const optionalColumnsSwitchers = useMemo(() => getOptionalColumnsSwitchers(myRoleType, page), [myRoleType, page]);

  useEffect(() => {
    if (!isChangeColumnsModalOpened) {
      setData(columnsSettings);
    }
  }, [isChangeColumnsModalOpened]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ModalWindow modalID={CHANGE_COLUMN_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('editColumnsModalTitle')}</h5>
      </div>
      <div className="modal-body">
        {optionalColumnsSwitchers.map(({ i18nLabel, key, show }) => (show ? (
          <div className="text-confirmation switch-container" key={key}>
            <Switch
              checked={!!data?.[key]}
              width={51}
              height={26}
              onColor="#779B42"
              className="react-switch"
              onChange={() => handleChange(key)}
            />
            <span className="tarif-header">
              {i18n.t(i18nLabel)}
            </span>
          </div>
        ) : null))}
      </div>
      <div className="modal-footer">
        <CancelButton onClickHandler={handleCancelBtnClick} />
        <PrimaryButton onClickHandler={handleSave} type="save" />
      </div>
    </ModalWindow>
  );
};

ChangeColumnsModalWindow.propTypes = {
  dataListID: PropsTypes.string.isRequired,
  page: PropsTypes.string
};

export default ChangeColumnsModalWindow;
