import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

import { minLength, maxLength, required, email, parseToLowerCase } from '../../validation';
import { CustomTextInput } from '../../../components/ReduxFormFields';
import { Tooltip } from '../../../components';

import i18n from '../../../i18n';

import '../../../App.css';

const minLen3 = minLength(3);
const maxLen50 = maxLength(50);
/**
 * @description renders SignUp page for users
 * @memberof module:SignUp
 * @extends Component
 */

const SignUpUser = (props) => {
  const { handleSubmit, openQRScanner, pristine, submitting } = props;

  return (
    <>
      <form className="m-login__form m-form">
        <div className="tooltip-for-signup">
          <Tooltip title={i18n.t('SMIDTolltip')} />
        </div>
        <div className="form-group m-form__group input-field">
          <Field
            name="sm_id"
            component={CustomTextInput}
            label={i18n.t('reqSMID')}
            className="m-input"
            validate={[required, minLen3, maxLen50]}
          />
        </div>
        <div className="form-group m-form__group input-field">
          <Field
            name="email"
            component={CustomTextInput}
            label={i18n.t('regMail')}
            className="m-input"
            autoComplete="off"
            validate={[required, email]}
            parse={parseToLowerCase}
            preventAuto
          />
        </div>
        <div className="m-login__form-action">
          <button
            onClick={handleSubmit}
            disabled={pristine || submitting}
            type="submit"
            id="m_login_signin_submit"
            className="gradient-btn"
          >
            {i18n.t('submitBtn')}
          </button>
          <div className="m-login__account">
            <a
              className="m-link m-link--light m-login__account-link qrscan"
              onClick={openQRScanner}
            >
              <i className="la la-qrcode" />
              <br />
              {i18n.t('QRLink')}
            </a>
            <span className="qr-code-tooltip">
              <Tooltip title={i18n.t('qrCodeTooltip')} />
            </span>
          </div>
        </div>
      </form>
      <div className="m-login__account">
        <Link
          to="/login"
          id="m_login_signup"
          className="m-link m-link--light m-login__account-link"
        >
          {i18n.t('haveAcc')}
        </Link>
      </div>
    </>
  );
};

SignUpUser.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  openQRScanner: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
};

const form = reduxForm({
  form: 'signUpUserForm'
})(SignUpUser);
export default form;
