import { find, get } from 'lodash';
import i18n from '../../../i18n';

const formatPeaks = (value) => `${Math.round(value)} W`;

export default function calculatePeaks(peak_production, peak_consumption, chart) {
  const peaks = { lines: [] };

  if (peak_production) {
    const prodDataMax = chart?.yAxis[0]?.series?.find(({ name }) => name?.toLowerCase() === 'production')?.dataMax;
    const prodPoints = chart?.series?.find(({ name }) => name?.toLowerCase() === 'production')?.points;

    if (prodDataMax) {
      const prodMaxValue = get(find(prodPoints, (point) => point.y === prodDataMax), 'y');

      if (prodMaxValue) {
        peaks.prodMaxValue = prodMaxValue;
        peaks.lines.push({
          zIndex: 100,
          value: prodMaxValue,
          color: '#c59125',
          dashStyle: '4px 4px',
          width: 2,
          label: {
            text: `${i18n.t('prodMax')}: ${formatPeaks(prodMaxValue)}`,
            useHTML: true,
            style: {
              color: 'var(--primary-color)',
              background: '#fafafa',
              padding: '0 5px',
              fontSize: '13px',
              fontWeight: 500,
              transform: 'translateY(-3px)'
            }
          }
        });
      }
    }
  }

  if (peak_consumption) {
    const consDataMax = chart?.yAxis[0]?.series?.find(({ name }) => name?.toLowerCase() === 'consumption')?.dataMax;
    const consPoints = chart?.series?.find(({ name }) => name?.toLowerCase() === 'consumption')?.points;

    if (consDataMax) {
      const consMaxValue = get(find(consPoints, (point) => point.y === consDataMax), 'y');

      if (consMaxValue) {
        peaks.consMaxValue = consMaxValue;
        peaks.lines.push({
          zIndex: 100,
          value: consMaxValue,
          color: '#30639a',
          dashStyle: '4px 4px',
          width: 2,
          label: {
            text: `${i18n.t('consMax')}: ${formatPeaks(consMaxValue)}`,
            useHTML: true,
            align: 'right',
            style: {
              padding: '0 5px',
              color: '#3998f9',
              background: '#fafafa',
              fontSize: '13px',
              fontWeight: 500,
              transform: 'translate(-30px, -3px)'
            }
          }
        });
      }
    }
  }

  return peaks;
}
