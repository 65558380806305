const replace = {
  changeSMID: 'Ændre CKW ID',
  reqSMID: 'CKW ID *',
  SMID: 'CKW ID',
  SMIDTolltip: 'The CKW ID er en unik identifikator for din enhed. Du finder dette ID på enheden under QR-koden.',
  enableSmId: 'CKW ID',
  smidLabel: 'CKW ID',
  confirmDeletingDesc: 'Bekræft sletningen af ​​alle data fra denne gateway ved at indtaste CKW ID. Efter bekræftelse kan denne proces ikke vendes.',
  confirmDeletingError: 'ID\'et stemmer ikke overens med CKW-ID\'et. Gateway\'en er ikke slettet.'
};

export default replace;
