/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import { LanguageSelector, CustomTextInput, CustomSelect, CommonPhoneInput, CountrySelector, MultipleSelect } from '../../../components/ReduxFormFields';
import { required, minLength, maxLength, checkValidPhone, requiredMultiple } from '../../validation';
import { SETTING_PROFILE_FORM, statusList } from '../constants';
import { CancelButton } from '../../../components/UIKit';
import { AvatarPreviewer } from '../components';
import { useZipCodeValidation } from '../../../hooks';
import { countryLabels } from '../../../components/ReduxFormFields/CountrySelector/constants';

import i18n from '../../../i18n';

import '../../../App.css';

const countriesOptions = [{
  value: 'allCountries',
  label: i18n.t('allCountries'),
  id: 'allCountries'
},
...Object.values(countryLabels).map((key) => ({
  value: key,
  label: key,
  id: key
}))];

const valueSelector = formValueSelector(SETTING_PROFILE_FORM);

const minLen3 = minLength(3);
const maxLen50 = maxLength(50);

/**
 * Edit profile of user with roleType = 'solar_admin' - FormAdmin
 * @memberof module:SettingProfile
 */
const FormAdmin = (props) => {
  const {
    openChangePassword,
    openChangeEmail,
    initialValues,
    handleSubmit,
    userRoleName,
    initialize,
    myRoleType,
    submitting,
    pristine,
    goBack,
    myself
  } = props;

  const first_name = useSelector((state) => valueSelector(state, 'first_name'));
  const last_name = useSelector((state) => valueSelector(state, 'last_name'));
  const zipValidation = useZipCodeValidation(valueSelector);

  useEffect(() => {
    initialize(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPlaceholder = (selected) => {
    if (!selected.length) {
      return i18n.t('selectCountries');
    }

    return selected.length === countriesOptions.length - 1
      ? i18n.t('allCountries')
      : selected.join(', ');
  };

  const roleDependencies = (!myself && myRoleType === 'viewer') ? { disabled: true } : {};

  return (
    <form>
      <div className="nav-settings">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-auto mr-sm-auto">
              <h6 className="set-prof-head">
                {i18n.t('settingsProfile')}
                {!myself && (
                  <>
                    :&nbsp;
                    {i18n.t(userRoleName)}
                  </>
                )}
              </h6>
            </div>
            {!roleDependencies.disabled && (
              <div className="col-auto">
                <button
                  disabled={pristine || submitting}
                  onClick={handleSubmit}
                  className="gradient-btn"
                  type="button"
                >
                  {i18n.t('saveChanges')}
                </button>
              </div>
            )}
            <div className="col-auto cont-for-canc-btn">
              <CancelButton onClickHandler={goBack} customButtonClass="emp-set-btn-canc" />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row align-items-md-start justify-content-md-start justify-content-center settings-user-details">
          <div className="col-md-3 col-auto user-photo">
            <Field
              name="avatar"
              type="file"
              userName={`${first_name} ${last_name}`}
              avatar={initialValues?.avatarURL}
              component={AvatarPreviewer}
              {...roleDependencies}
            />
          </div>
          <div className="col-md-8">
            <div className="row justify-content-between">
              <div className="col-lg-5">
                {(!myself && myRoleType === 'root')
                  && (
                    <div className="form-group m-form__group input-field">
                      <div className="flags-select-label">{i18n.t('status')}</div>
                      <Field
                        name="status"
                        component={CustomSelect}
                        placeholder={i18n.t('selectStatus')}
                        options={statusList()}
                        isSearchable={false}
                        validate={[required]}
                        filterOption={({ value }) => (value === 'active' || value === 'deactivated')}
                        {...roleDependencies}
                      />
                    </div>
                  )}
                <div className="form-group m-form__group input-field">
                  <Field
                    name="first_name"
                    component={CustomTextInput}
                    label={i18n.t('reqFirstName')}
                    className="m-input"
                    autoComplete="off"
                    validate={[required, minLen3, maxLen50]}
                    {...roleDependencies}
                  />
                </div>
                <div className="form-group m-form__group input-field">
                  <Field
                    name="last_name"
                    component={CustomTextInput}
                    label={i18n.t('reqLastName')}
                    className="m-input"
                    autoComplete="off"
                    validate={[required, minLen3, maxLen50]}
                    {...roleDependencies}
                  />
                </div>
                <div className="form-group m-form__group input-field countries-select">
                  <Field
                    id="authorizedCountries"
                    name="authorizedCountries"
                    component={MultipleSelect}
                    label={i18n.t('authorizedCountries')}
                    className="m-input"
                    options={countriesOptions}
                    placeholder={i18n.t('selectCountries')}
                    renderValue={renderPlaceholder}
                    required
                    allSelectedKey="allCountries"
                    disabled={myself}
                    validate={[requiredMultiple]}
                  />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="form-group m-form__group input-field input-field-for-ch-pass">
                  <Field
                    name="old_email"
                    disabled
                    component={CustomTextInput}
                    label={i18n.t('mail')}
                    className="m-input"
                  />
                  {!roleDependencies.disabled && (
                    <button
                      type="button"
                      onClick={openChangeEmail}
                      className="change-btn"
                    >
                      {i18n.t('changeMail')}
                    </button>
                  )}
                </div>
                {myself && (
                  <div className="form-group m-form__group input-field input-field-for-ch-pass">
                    <Field
                      name="password"
                      type="password"
                      disabled
                      component={CustomTextInput}
                      label="pass"
                      className="m-input"
                      input={{ value: 'password' }}
                    />
                    <button
                      type="button"
                      onClick={openChangePassword}
                      className="change-btn"
                    >
                      {i18n.t('changePass')}
                    </button>
                  </div>
                )}
                <div className="form-group m-form__group input-field input-filed-flags-select">
                  <div className="flags-select-label">
                    {i18n.t('Country')}
                  </div>
                  <Field
                    name="country"
                    id="country"
                    component={CountrySelector}
                    buttonClassName="country-btn"
                    label={i18n.t('Country')}
                    className="m-input flagSelectClosed"
                    {...roleDependencies}
                    validate={[required]}
                  />
                </div>
                <div className="form-group m-form__group input-field input-filed-flags-select">
                  <div className="flags-select-label">{i18n.t('reqLanguage')}</div>
                  <Field
                    name="language"
                    component={LanguageSelector}
                    id="reqLanguage"
                    buttonClassName="reqLanguage-btn"
                    label={i18n.t('reqLanguage')}
                    className="m-input flagSelectClosed"
                    {...roleDependencies}
                  />
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
        <div className="row align-items-md-start justify-content-md-start justify-content-center">
          <div className="col-md-3 col-auto user-photo" />
          <div className="col-md-8">
            <div className="row justify-content-between align-items-start">
              <div className="col-lg-5">
                <div className="form-group m-form__group input-field">
                  <Field
                    name="street"
                    component={CustomTextInput}
                    label={i18n.t('street')}
                    className="m-input"
                    autoComplete="off"
                    validate={[minLen3, maxLen50, required]}
                    isRequired
                    {...roleDependencies}
                  />
                </div>
                <div className="row city-zip">
                  <div className="form-group m-form__group input-field col-7 city-inp city-inp-comp-set">
                    <Field
                      name="city"
                      component={CustomTextInput}
                      label={i18n.t('city')}
                      className="m-input"
                      autoComplete="off"
                      validate={[minLen3, maxLen50, required]}
                      isRequired
                      {...roleDependencies}
                    />
                  </div>
                  <div className="form-group m-form__group input-field offset-1 col-4 zip-inp zip-inp-comp-set">
                    <Field
                      name="zip"
                      component={CustomTextInput}
                      label={i18n.t('zip')}
                      className="m-input"
                      autoComplete="off"
                      validate={[zipValidation, required]}
                      isRequired
                      {...roleDependencies}
                    />
                  </div>
                </div>

              </div>
              <div className="col-lg-5">
                <div className="form-group m-form__group input-field">
                  <Field
                    name="phone"
                    component={CommonPhoneInput}
                    label={i18n.t('phoneNumber')}
                    className="m-input"
                    autoComplete="off"
                    country={initialValues?.country || 'Switzerland'}
                    phone={initialValues?.phone}
                    validate={[checkValidPhone, required]}
                    isRequired
                    {...roleDependencies}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

FormAdmin.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object).isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  myself: PropTypes.bool,
  myRoleType: PropTypes.string,
  userRoleName: PropTypes.string,
  openChangeEmail: PropTypes.func,
  openChangePassword: PropTypes.func,
  goBack: PropTypes.func
};

const form = reduxForm({
  form: SETTING_PROFILE_FORM
})(FormAdmin);

export default form;
