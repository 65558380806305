const replace = {
  changeSMID: 'Cambia l\'ID CKW OEM',
  reqSMID: 'CKW ID *',
  SMID: 'CKW ID',
  SMIDTolltip: 'Il CKW ID è un identificatore unico del tuo dispositivo. Questo ID si trova sul dispositivo sotto il codice QR.',
  enableSmId: 'CKW ID',
  smidLabel: 'CKW ID',
  confirmDeletingDesc: 'Conferma la cancellazione di tutti i dati di questo gateway inserendo l\'ID CKW. Dopo la conferma, questo processo non può essere invertito.',
  confirmDeletingError: 'L\'ID non corrisponde all\'ID CKW. Il gateway non è stato cancellato.'
};

export default replace;
