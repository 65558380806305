export const multiselectConfig = {
  multiselectContainer: {
    display: 'flex',
    alignItems: 'end',
    width: 'min-content',
    minWidth: '200px',
    maxWidth: '250px',
    minHeight: '38px'
  },
  searchBox: {
    width: '100%',
    border: '1px solid #405E1F',
    borderRadius: '8px',
    backgroundColor: '#fff'
  },
  chips: {
    backgroundColor: '#AED17C',
    color: '#405E1F',
    fontWeight: 400,
    whiteSpace: 'normal'
  },
  optionContainer: {
    position: 'absolute',
    width: '100%',
    border: 'none',
    backgroundColor: '#fff',
    top: '4px'
  },
  option: {
    color: '#000',
    fontWeight: 400
  },
  inputField: {
    maxWidth: '240px'
  }
};

export const READ_ONLY_ROLES = ['pv_installer_employee_read_only', 'oem_employee_read_only'];
