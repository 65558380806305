const replace = {
  changeSMID: 'CKW ID ändern',
  reqSMID: 'CKW ID *',
  SMID: 'CKW ID',
  SMIDTolltip: 'Die CKW ID ist eine eindeutige Kennung Ihres Gerätes. Sie finden diese ID auf dem Gerät unterhalb des QR-Codes.',
  enableSmId: 'CKW ID',
  smidLabel: 'CKW ID',
  confirmDeletingDesc: 'Bestätigen Sie das Löschen aller Daten dieses Gateways, indem Sie die CKW -ID eingeben. Nach Bestätigung kann dieser Vorgang nicht rückgängig gemacht werden.',
  confirmDeletingError: 'Die ID stimmt nicht mit der CKW-ID überein. Das Gateway wird nicht gelöscht.'
};

export default replace;
