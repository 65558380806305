const replace = {
  changeSMID: 'Change CKW ID',
  reqSMID: 'CKW ID *',
  SMID: 'CKW ID',
  SMIDTolltip: 'The CKW ID is a unique identifier of your device. You find this ID on the device below the QR code.',
  enableSmId: 'CKW ID',
  smidLabel: 'CKW ID',
  confirmDeletingDesc: 'Confirm deleting of all data of this gateway by entering the CKW ID. After pressing Confirm this process cannot be reversed.',
  confirmDeletingError: 'The ID does not match with the CKW ID. The gateway is not deleted.'
};

export default replace;
