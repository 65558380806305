import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ModalWindow from '../../containers/ModalWindow';
import { closeModalWindow } from '../../containers/ModalWindow/slice';
import { PrimaryButton, CancelButton } from '../../components/UIKit';
// import { ReactComponent as SolarIcon } from '../../assets/images/svg/solar-icon.svg';

import i18n from '../../i18n';
import './index.scss';
import { getUserDevicesUser_IdSelector } from '../../redux-store/selectors/userDevices';
import { getSignInUserRoleTypeSelector } from '../../redux-store/selectors/signIn';
import { getPaymentUrlRequest } from '../../containers/Payments/slice';

const UpgradeToUnlockPopUp = ({ modalID }) => {
  const dispatch = useDispatch();
  const myRoleType = useSelector(getSignInUserRoleTypeSelector);
  const currentUserDeviceId = useSelector(getUserDevicesUser_IdSelector);
  const isHaveAccessToChangePlan = ['root', 'solar_admin', 'pv_installer', 'end_user'].includes(
    myRoleType
  );

  const redirectToPaymentHandler = () => dispatch(
    getPaymentUrlRequest({ userId: myRoleType === 'end_user' ? undefined : currentUserDeviceId })
  );

  const handleClose = () => {
    dispatch(closeModalWindow({ modalID }));
  };

  return (
    <ModalWindow isModalOver customOnClose={handleClose} modalID={modalID}>
      <div className="modal-header">
        {/* <SolarIcon /> */}
        <h5 className="modal-title">{i18n.t('upgradeToUnlock')}</h5>
      </div>

      <div>
        <div className="modal-body">
          {i18n.t(
            isHaveAccessToChangePlan
              ? 'upgradeToUnlockPopUpMessage1'
              : 'upgradeToUnlockPopUpMessage2'
          )}
        </div>
        <div className="modal-footer">
          {isHaveAccessToChangePlan ? (
            <>
              <CancelButton onClickHandler={handleClose} i18nLabel="later" />
              <PrimaryButton onClickHandler={redirectToPaymentHandler} i18nLabel="upgrade" />
            </>
          ) : (
            <PrimaryButton onClickHandler={handleClose} i18nLabel="ok" />
          )}
        </div>
      </div>
    </ModalWindow>
  );
};

UpgradeToUnlockPopUp.propTypes = {
  modalID: PropTypes.string.isRequired
};

export default UpgradeToUnlockPopUp;
