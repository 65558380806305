/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';

import { CommonPhoneInput, LanguageSelector, CustomTextInput, CustomSelect, Checkbox, CountrySelector } from '../../../components/ReduxFormFields';
import { CompanyLogoPreview, AvatarPreviewer } from '../components';
import CancelButton from '../../../components/UIKit/CancelButton';
import { Tooltip } from '../../../components';
import { SETTING_PROFILE_FORM, statusList } from '../constants';
import { settingPrClearValues } from '../slice';
import {
  required,
  minLength,
  maxLength,
  webpage,
  checkValidPhone,
  validationURL
} from '../../validation';
import { useZipCodeValidation } from '../../../hooks';
import i18n from '../../../i18n';

import '../../../App.css';

const valueSelector = formValueSelector(SETTING_PROFILE_FORM);

const minLen3 = minLength(3);
const maxLen50 = maxLength(50);
/**
 * Edit profile of user with roleType = 'pv_installer' - FormCompany
 * @memberof module:SettingProfile
 */
const FormCompany = (props) => {
  const {
    openChangePassword,
    openChangeEmail,
    initialValues,
    handleSubmit,
    userRoleName,
    userRoleType,
    initialize,
    myRoleType,
    submitting,
    pristine,
    myself,
    goBack,
    sendEmailRequest
  } = props;
  const dispatch = useDispatch();

  const [logo, setLogo] = useState(initialValues.companyLogoUrl);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(undefined);

  const first_name = useSelector((state) => valueSelector(state, 'first_name'));
  const last_name = useSelector((state) => valueSelector(state, 'last_name'));

  const zipValidation = useZipCodeValidation(valueSelector);

  const deleteLogo = () => {
    dispatch(change(SETTING_PROFILE_FORM, 'link_for_logo', ''));
    setLogo(null);
  };
  const getImagePreviewUrl = (url) => setImagePreviewUrl(url);

  const roleDependencies = (!myself && (['viewer', 'oem_employee_read_only'].includes(myRoleType))) ? { disabled: true } : {};
  useEffect(() => {
    initialize(initialValues);

    return () => {
      if (myRoleType !== 'pv_installer') {
        dispatch(settingPrClearValues());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div className="nav-settings">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-auto mr-sm-auto">
              <h6 className="set-prof-head">
                {i18n.t('settingsProfile')}
                {!myself && (
                  <>
                    :&nbsp;
                    {userRoleName ? `${i18n.t(userRoleName)}` : `${i18n.t(userRoleType)}`}
                  </>
                )}
              </h6>
            </div>
            {!roleDependencies.disabled && (
              <div className="col-auto">
                <button
                  disabled={pristine || submitting}
                  onClick={handleSubmit}
                  className="gradient-btn"
                  type="button"
                >
                  {i18n.t('saveChanges')}
                </button>
              </div>
            )}
            <div className="col-auto cont-for-canc-btn">
              <CancelButton onClickHandler={goBack} customButtonClass="emp-set-btn-canc" />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row align-items-md-start justify-content-md-start justify-content-center settings-user-details">
          <div className="col-md-3 col-auto user-photo">
            <Field
              name="avatar"
              type="file"
              userName={`${first_name} ${last_name}`}
              avatar={initialValues?.avatarURL}
              component={AvatarPreviewer}
              {...roleDependencies}
            />
          </div>
          <div className="col-md-8">
            <div className="row justify-content-between">
              <div className="col-lg-5">
                {(['root', 'solar_admin', 'property_management'].includes(myRoleType) || (['oem'].includes(myRoleType) && !myself)) && (
                  <div className="form-group m-form__group input-field">
                    <div className="flags-select-label">
                      {i18n.t('status')}
                    </div>
                    <Field
                      name="status"
                      component={CustomSelect}
                      placeholder={i18n.t('selectStatus')}
                      options={statusList()}
                      isSearchable={false}
                      validate={[required]}
                      filterOption={({ value }) => (value === 'active' || value === 'deactivated')}
                      {...roleDependencies}
                    />
                  </div>
                )}
                <div className="form-group m-form__group input-field">
                  <Field
                    name="company"
                    component={CustomTextInput}
                    label={i18n.t('reqCompany')}
                    className="m-input"
                    autoComplete="off"
                    validate={[required, minLen3, maxLen50]}
                    {...roleDependencies}
                  />
                </div>
                <div className="form-group m-form__group input-field">
                  <Field
                    name="first_name"
                    component={CustomTextInput}
                    label={i18n.t('reqFirstName')}
                    className="m-input"
                    autoComplete="off"
                    validate={[required, minLen3, maxLen50]}
                    {...roleDependencies}
                  />
                </div>
                <div className="form-group m-form__group input-field">
                  <Field
                    name="last_name"
                    component={CustomTextInput}
                    label={i18n.t('reqLastName')}
                    className="m-input"
                    autoComplete="off"
                    validate={[required, minLen3, maxLen50]}
                    {...roleDependencies}
                  />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="form-group m-form__group input-field input-field-for-ch-pass">
                  <Field
                    name="old_email"
                    disabled
                    component={CustomTextInput}
                    label={i18n.t('mail')}
                    className="m-input"
                  />
                  {!roleDependencies.disabled && (
                    <>
                      <button
                        type="button"
                        onClick={sendEmailRequest}
                        className="resend-btn"
                      >
                        {i18n.t('resendEmail')}
                      </button>
                      <button
                        type="button"
                        onClick={openChangeEmail}
                        className="change-btn"
                      >
                        {i18n.t('changeMail')}
                      </button>
                    </>
                  )}
                </div>
                {myself && (
                  <div className="form-group m-form__group input-field input-field-for-ch-pass">
                    <Field
                      name="password"
                      type="password"
                      disabled
                      component={CustomTextInput}
                      label="pass"
                      className="m-input"
                      input={{ value: 'password' }}
                    />
                    <button
                      type="button"
                      onClick={openChangePassword}
                      className="change-btn"
                    >
                      {i18n.t('changePass')}
                    </button>
                  </div>
                )}
                <div className="form-group m-form__group input-field input-filed-flags-select">
                  <div className="flags-select-label">
                    {i18n.t('Country')}
                  </div>
                  <Field
                    name="country"
                    id="country"
                    component={CountrySelector}
                    buttonClassName="country-btn"
                    label={i18n.t('Country')}
                    className="m-input flagSelectClosed"
                    {...roleDependencies}
                    validate={[required]}
                  />
                </div>
                <div className="form-group m-form__group input-field input-filed-flags-select">
                  <div className="flags-select-label">{i18n.t('reqLanguage')}</div>
                  <Field
                    name="language"
                    component={LanguageSelector}
                    id="reqLanguage"
                    buttonClassName="reqLanguage-btn"
                    label={i18n.t('reqLanguage')}
                    className="m-input flagSelectClosed"
                    {...roleDependencies}
                  />
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
        <div className="row align-items-md-start justify-content-md-start justify-content-center">
          <div className="col-md-3 col-auto company-logo">
            <div className="row justify-content-center">
              <div className="col-auto">
                <div className="row justify-content-center">
                  <div className="col-12 d-flex flex-column align-items-center">
                    <Field
                      name="company_logo"
                      type="file"
                      userName={`${first_name} ${last_name}`}
                      logo={logo}
                      component={CompanyLogoPreview}
                      {...roleDependencies}
                      getImagePreviewUrl={getImagePreviewUrl}
                      deleteLogo={deleteLogo}
                    />
                    {['pv_installer', 'oem', 'oem_employee', 'property_management', 'solar_admin', 'root'].includes(myRoleType) && (
                      <div className="form-group m-form__group input-field link-for-logo">
                        <Field
                          name="link_for_logo"
                          component={CustomTextInput}
                          label={i18n.t('linkForLogoLabel')}
                          showTooltip={i18n.t('linkForLogoTooltip')}
                          className="m-input"
                          autoComplete="off"
                          validate={[validationURL]}
                          disabled={!imagePreviewUrl && !logo}
                        />
                      </div>
                    )}
                    <div className="show-logo-header">
                      {i18n.t('Show logo in')}
                      <Tooltip
                        title={userRoleType === 'oem' ? i18n.t('oemLogoTooltip') : i18n.t('compLogoTooltip')}
                        placement="right"
                      />
                      :
                    </div>
                    <div className="checkboxes-container">
                      <Field
                        type="checkbox"
                        name="visible_web"
                        component={Checkbox}
                        labelClass="m-checkbox terms m-checkbox--light remem-checkbox"
                        label={i18n.t('Web App')}
                        {...roleDependencies}
                      />
                      <Field
                        type="checkbox"
                        name="visible_installer_app"
                        component={Checkbox}
                        labelClass="m-checkbox terms m-checkbox--light remem-checkbox"
                        label={i18n.t('Installer App')}
                        {...roleDependencies}
                      />
                      <Field
                        type="checkbox"
                        name="visible_end_user_app"
                        component={Checkbox}
                        labelClass="m-checkbox terms m-checkbox--light remem-checkbox"
                        label={i18n.t('End User App')}
                        {...roleDependencies}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="row justify-content-between align-items-start">
              <div className="col-lg-5">
                <div className="form-group m-form__group input-field">
                  <Field
                    name="street"
                    component={CustomTextInput}
                    label={i18n.t('reqStreet')}
                    className="m-input"
                    autoComplete="off"
                    validate={[required, minLen3, maxLen50]}
                    {...roleDependencies}
                  />
                </div>
                <div className="row city-zip">
                  <div className="form-group m-form__group input-field col-7 city-inp city-inp-comp-set">
                    <Field
                      name="city"
                      component={CustomTextInput}
                      label={i18n.t('reqCity')}
                      className="m-input"
                      autoComplete="off"
                      validate={[required, minLen3, maxLen50]}
                      {...roleDependencies}
                    />
                  </div>
                  <div className="form-group m-form__group input-field offset-1 col-4 zip-inp zip-inp-comp-set">
                    <Field
                      name="zip"
                      component={CustomTextInput}
                      label={i18n.t('reqZIP')}
                      className="m-input"
                      autoComplete="off"
                      validate={[required, zipValidation]}
                      {...roleDependencies}
                    />
                  </div>
                </div>

              </div>
              <div className="col-lg-5">
                <div className="form-group m-form__group input-field">
                  <Field
                    name="phone"
                    component={CommonPhoneInput}
                    label={i18n.t('reqPhoneNumber')}
                    className="m-input"
                    autoComplete="off"
                    country={initialValues?.country || 'Switzerland'}
                    phone={initialValues?.phone}
                    validate={[checkValidPhone, required]}
                    {...roleDependencies}
                  />
                </div>
                <div className="form-group m-form__group input-field">
                  <Field
                    name="webpage"
                    component={CustomTextInput}
                    label={`${i18n.t('webpage')} *`}
                    className="m-input"
                    autoComplete="off"
                    validate={[webpage, maxLen50, required]}
                    {...roleDependencies}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

FormCompany.propTypes = {
  initialize: PropTypes.func.isRequired,
  sendEmailRequest: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func,
  openChangeEmail: PropTypes.func,
  openChangePassword: PropTypes.func,
  myRoleType: PropTypes.string,
  userRoleName: PropTypes.string,
  myself: PropTypes.bool,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  userRoleType: PropTypes.string
};

const form = reduxForm({
  form: SETTING_PROFILE_FORM,
  initialValues: {
    visibleWeb: false,
    visibleInstallerApp: false,
    visibleEndUserApp: false
  }
})(FormCompany);

export default form;
